<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <editor-input-link
                v-model="post_links"
                :value="post_links"
                :field.sync="post_links"
                :prepend_type="prepend_type"
                legend="Link"
                :errors="isValid ? '' : 'Data is invalid'"
                :is-valid="isValid"
                :placeholder="'Link'"
                type="text"
                name="post_links"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="setFinalize"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import EditorInputLink from '@/components/CustomEditor/Editor/components/EditorFormElements/EditorInputLink'

export default {
    name: 'FinalizeModal',
    components: {
        EditorInputLink
    },
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        title: String,
        name: String,
        contentType: String,
        isValid: Boolean,
        prepend_data: {
            type: String,
            required: false
        },
        prepend_type: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            post_links: null
        }
    },
    mounted() {
        if (this.prepend_data) {
            this.post_links = this.prepend_data
        }
    },
    methods: {
        setFinalize() {
            const {
                post_links
            } = this
            this.$emit('setFinalize', { post_links, callback: this.callback })
        },
        callback() {
            this.confirmModal()
        }
    }
}
</script>
