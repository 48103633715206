import PreviewTextField from '@/components/CustomEditor/Preview/components/PreviewTextField';
import PreviewImgField from '@/components/CustomEditor/Preview/components/PreviewImgField';
import PreviewDescriptionField from '@/components/CustomEditor/Preview/components/PreviewDescriptionField';

import Editor from '@/components/CustomEditor/Editor'
import Preview from '@/components/CustomEditor/Preview'
import { mapGetters } from 'vuex'
import { isEqual } from 'lodash';

export default {
    components: {
        PreviewTextField,
        PreviewImgField,
        PreviewDescriptionField,
        Editor,
        Preview
    },
    props: {
        contentType: {
            type: String,
            required: true,
            validator(value) {
                return [
                    'landing',
                    'blog',
                    'essay',
                    'task'
                ].indexOf(value) !== -1
            }
        },
        mode_type: {
            type: String,
            required: true,
            validator(value) {
                return [
                    'create',
                    'preview',
                    'edit',
                    'revision',
                    'history'
                ].indexOf(value) !== -1
            }
        },
        hasPreview: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            modal: {
                title: '',
                content: '',
                name: ''
            },
            serverErrors: null
        }
    },
    computed: {
        ...mapGetters('auth', {
            userPermissions: 'getterUserPermissions'
        }),
        fields() {
            if (this.mode_type === 'edit') {
                return this.editItems
            }
            if (this.mode_type === 'revision') {
                return this.revisionItems
            }
            return this.createItems
        },
        validationRules() {
            if (this.mode_type === 'edit') {
                return this.validationEditRules
            }
            if (this.mode_type === 'revision') {
                return this.validationRevisionRules
            }
            if (this.mode_type === 'create') {
                return this.validationCreateRules
            }
            return {}
        },
        showRevisionButton() {
            const routePermission = this.$router.resolve({ name: `posts-list-${this.contentType}` }).resolved.meta.permission
            return !routePermission || this.userPermissions.includes(routePermission)
        },
        showEditButton() {
            const routePermission = this.$router.resolve({ name: `edit-post-${this.contentType}-revision` }).resolved.meta.permission
            return !routePermission || this.userPermissions.includes(routePermission)
        },
        checkIsEqual() {
            return isEqual(this.form_data, this.default_form_data)
        }
    },
    watch: {
        '$route.name': function (val) {
            if (val.includes('preview')) {
                this.$bus.$emit('updateNavEdit',
                    {
                        editing: false,
                        routeName: val
                    })
            }
            if (val.includes('edit-post-task')) {
                this.$bus.$emit('editingEnabled')
            }
        },
        form_data: {
            deep: true,
            handler() {
                if (!this.checkIsEqual) {
                    this.unfinished_session = true
                    this.unfinished_url = this.$route.path
                    this.unfinished_type = this.mode_type
                    return
                }
                this.unfinished_session = false
                this.unfinished_url = null
                this.unfinished_type = null
            }
        }
    },
    async activated() {
        if (this.hasPreview) {
            this.getCategories()
        } else {
            this.editing = true
            this.updateNavEdit()
        }

        this.$bus.$emit('setRevisionVisible', this.showRevisionButton)
        this.$bus.$emit('setEditVisible', this.showEditButton)
        if (this.$route.name === `create-post-${this.contentType}` && this.unfinished_type !== 'create') {
            this.reset()
        }
        if (this.mode_type !== 'create') {
            // eslint-disable-next-line radix
            if (this.$route.params.id && (this.id !== parseInt(this.$route.params.id) || this.$route.name.includes('preview'))) {
                await this.getData()
                this.lockChecker()
            }
            // eslint-disable-next-line radix
            if (this.id === parseInt(this.$route.params.id)) {
                if (!this.$route.name.includes('preview')) {
                    this.updateNavEdit()
                }
                this.lockChecker()
                return
            }
            if (!this.editing) {
                this.lockChecker()
                return;
            }
            this.getData()
            this.lockChecker()
            this.editing = false
        }
    },
    methods: {
        getNaming(item) {
            const {
                name, label, placeholder, legend
            } = item
            return {
                name, label, placeholder, legend
            }
        },
        submit(mode_type) {
            try {
                if (this.serverErrors && Object.keys(this.serverErrors).length > 0) { return }
                if (mode_type === 'revision') {
                    this.ACTION_CREATE_REVISION(this.form_data)
                        .then((resp) => {
                            this.serverErrors = this.getServerErrors(resp)
                            if (this.serverErrors) {
                                this.$bus.$emit('showSnackBar', this.serverErrors[Object.keys(this.serverErrors)[0]], 'error')
                                return
                            }
                            this.$bus.$emit('showSnackBar', `${this.contentType} revision was created`, 'success')
                            this.afterSubmitMethod()
                            this.unfinished_session = false
                            this.unfinished_url = null
                            this.unfinished_type = null
                            this.resetToPrew()
                        })
                } else if (mode_type === 'edit') {
                    this.ACTION_UPDATE_POST(this.form_data)
                        .then((resp) => {
                            this.serverErrors = this.getServerErrors(resp)
                            if (this.serverErrors) {
                                this.$bus.$emit('showSnackBar', this.serverErrors[Object.keys(this.serverErrors)[0]], 'error')
                                return
                            }
                            this.$bus.$emit('showSnackBar', `Your ${this.contentType} was updated`, 'success')
                            this.afterSubmitMethod()
                            this.unfinished_session = false
                            this.unfinished_url = null
                            this.unfinished_type = null
                            if (this.contentType === 'task') {
                                this.$router.push({
                                    name: 'posts-list-tasks'
                                })
                            }
                        })
                        .catch((err) => {
                            this.$bus.$emit('showSnackBar', err, 'error')
                        })
                } else if (mode_type === 'create') {
                    this.ACTION_CREATE_POST(this.form_data)
                        .then((resp) => {
                            const createErrors = { ...resp?.response?.data?.errors }
                            this.serverErrors = this.getServerErrors(resp)
                            if (createErrors?.post_links?.length) {
                                this.$bus.$emit('showSnackBar', createErrors.post_links.reduce((acc, curr) => `${acc + curr}. `, ''), 'error')
                                return
                            }
                            if (this.serverErrors) {
                                this.$bus.$emit('showSnackBar', this.serverErrors[Object.keys(this.serverErrors)[0]], 'error')
                                return
                            }
                            this.$bus.$emit('showSnackBar', `Your ${this.contentType} was created`, 'success')
                            this.unfinished_session = false
                            this.unfinished_url = null
                            this.unfinished_type = null
                            this.reset()
                            if (this.contentType === 'task') {
                                this.$router.push({
                                    name: 'posts-list-tasks'
                                })
                            }
                            if (this.hasPreview) {
                                this.$router.push({ name: `posts-list-${this.contentType}` })
                            } else {
                                this.$router.push({
                                    name: `posts-list-${this.contentType}s`
                                })
                            }
                        })
                        .catch((err) => {
                            this.$bus.$emit('showSnackBar', err, 'error')
                        })
                }
            } catch (e) {
                this.$bus.$emit('showSnackBar', 'Something went wrong', 'error')
            }
        },
        getServerErrors(resp) {
            const serverErrors = resp?.response?.data?.errors || null
            if (serverErrors) {
                // eslint-disable-next-line no-restricted-syntax,guard-for-in
                for (const key in serverErrors) {
                    // eslint-disable-next-line prefer-destructuring
                    serverErrors[key] = serverErrors[key][0]
                }
            }
            return serverErrors
        },
        afterSubmitMethod() {
            this.$bus.$emit('postWasSaved')
            this.resetToPrew()
            this.editing = false
        },
        checkRouteForValidate() {
            const routeList = [
                `edit-post-${this.contentType}`,
                `edit-post-${this.contentType}-revision`,
                `create-post-${this.contentType}`
            ]
            if (routeList.includes(this.$route.name)) {
                this.$refs.content ? this.$refs.content.validate() : this.validate()
            }
        },
        async reset() {
            const routeEditList = [
                `edit-post-${this.contentType}`,
                `edit-post-${this.contentType}-revision`,
                `create-post-${this.contentType}`,
                `edit-post-${this.contentType}-preview`
            ]
            if (routeEditList.includes(this.$route.name)) {
                this.RESET_EDIT_FORM_DATA()
                this.RESET_CREATED_FORM_DATA()
                if (this.$route.params.id) {
                    await this.getData()
                }
                this.$refs.content?.$v?.$reset()
            }
            if (this.$route.name === `create-post-${this.contentType}`) {
                this.RESET_CREATED_FORM_DATA()
                this.$refs.content?.$v?.$reset()
            }
        },
        resetToPrew() {
            setTimeout(() => {
                this.reset()
            }, 100)
            if (this.hasPreview) {
                this.$router.push({
                    name: `edit-post-${this.contentType}-preview`
                })
                return
            }

            this.$router.push({
                name: `posts-list-${this.contentType}s`
            })
        },
        async getData() {
            await this.ACTION_GET_POST({ id: this.$route.params.id })
        },
        lockChecker() {
            if (this.form_data.locked) {
                this.$bus.$emit('hideEditButtons')
                if (!this.$route.name.includes('preview')) {
                    this.$router.push({
                        name: `edit-post-${this.contentType}-preview`
                    })
                }
                return
            }
            this.$bus.$emit('showEditButtons')
        },
        getCategories() {
            this.ACTION_GET_CATEGORY_LIST_FOR_SELECT({
                path: this.contentType
            })
        },
        updateNavEdit() {
            this.$bus.$emit('updateNavEdit',
                {
                    editing: this.editing,
                    routeName: this.$route.name
                })
        },
        editPost() {
            this.editing = true
            this.updateNavEdit()
            this.$router.push({
                name: `edit-post-${this.contentType}`
            })
        },
        createRevision() {
            this.editing = true
            this.updateNavEdit()
            this.$router.push({
                name: `edit-post-${this.contentType}-revision`
            })
        },
        setModalConfig(modalTitle, modalContent, modalName) {
            this.modal = {
                title: modalTitle,
                content: modalContent,
                name: modalName
            }
        }
    }
}
