import { render, staticRenderFns } from "./EditorHeaderTabs.vue?vue&type=template&id=7e2eae9d&scoped=true&"
import script from "./EditorHeaderTabs.vue?vue&type=script&lang=js&"
export * from "./EditorHeaderTabs.vue?vue&type=script&lang=js&"
import style0 from "./EditorHeaderTabs.vue?vue&type=style&index=0&id=7e2eae9d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e2eae9d",
  null
  
)

export default component.exports