<template>
    <div class="editor-layout__header">
        <div class="editor-layout__header-info__block">
            <button
                class="editor-layout__header-info__block-back"
                @click="$router.back()"
            >
                <arrow />
                BACK
            </button>
            <div class="editor-layout__header-info__block-icons">
                <div class="editor-layout__header-info__block-icons_status">
                    <template v-if="item.published === 1">
                        <status-ok />
                        Published
                    </template>
                    <template v-else-if="item.published === 0 && mode !== 'create'">
                        <status-error />
                        Unpublished
                    </template>
                </div>
                <div
                    class="editor-layout__header-info__block-icons_status"
                >
                    <template v-if="item.locked === 1">
                        <lock />
                        Locked
                    </template>
                    <template v-else-if="item.hasOwnProperty('locked') && mode !== 'create'">
                        <unlock />
                        Unlocked
                    </template>
                </div>
                <div
                    v-if="item.revisions_count"
                    class="editor-layout__header-info__block-icons_revisions"
                >
                    <revisions
                        :id="id"
                        :revisions="item.revisions_count"
                        :revisions_count_new="item.revisions_count_new"
                        :type="type"
                        text
                    />
                </div>
            </div>
            <EditorHeaderTask
                v-if="showComments"
                :item="item"
            />
        </div>
        <editor-header-tabs
            v-if="showTabs"
            :type="type"
        />
        <h1 class="editor-layout__header-title">
            {{ mode === 'revision' ? `New ${ type } revision` : title }}
        </h1>
    </div>
</template>

<script>
import Arrow from '@/icons/Arrow';
import Revisions from '@/components/Revisions';
import Unlock from 'mdi-vue/LockOpen';
import EditorHeaderTabs from './EditorHeaderTabs';
import EditorHeaderTask from './EditorHeaderTask';

export default {
    name: 'EditorHeader',
    components: {
        Arrow,
        Unlock,
        Revisions,
        EditorHeaderTabs,
        EditorHeaderTask
    },
    props: {
        title: String,
        type: {
            type: String,
            default: 'landing'
        },
        mode: {
            type: String
        },
        id: Number,
        item: Object
    },
    data() {
        return {
            showPopup: false
        }
    },
    computed: {
        showTabs() {
            return this.mode === 'preview' || this.mode === 'history' || (this.type === 'task' && this.mode === 'edit')
        },
        showComments() {
            return (this.type === 'task' && this.mode === 'edit')
        }
    },
    methods: {
        openCommentsPopup() {
            this.showPopup = true
        },
        hidePopup() {
            this.showPopup = false
        }
    }
}
</script>

<style lang="scss" scoped>
    .comment_task{
        & > p {
            display: flex;
            align-items: center;
             span {
                &:last-child{
                    margin-left: 10px;
                    font-weight: bold;
                }
             }
        }
        .bubble{
            cursor: pointer;
            width: 60px;
            height: 60px;
            color: $orange;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $round-count;
            border-radius: 50%;
            &--empty{
                background: #e0e0e0;
                color: #000;
            }
        }
    }
    .editor-layout__header {
        margin-bottom: 30px;
        &-info__block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            &-back {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                height: 40px;
                width: 80px;
                font-size: 16px;
                font-weight: bold;
                outline: none;
                cursor: pointer;
                transition: .1s;
                svg {
                    margin-right: 10px;
                    height: 14px;
                }
                &:hover {
                    font-size: 18px;
                    svg {
                        height: 16px;
                    }
                }

            }
            &-icons {
                display: flex;
                align-items: center;
                &_status {
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    svg {
                        height: 28px;
                        width: 28px;
                        margin-right: 10px;
                    }
                    color: rgba(0,0,0,.87);
                }
                &_revisions {
                    margin-left: 20px;
                }
            }
        }
        &-title {
            font-size: 26px;
            margin: 0 0 10px;
            text-transform: capitalize;
        }
        &-subtitle {
            font-weight: 400;
            font-size: 16px;
            font-style: italic;
            margin: 0;
        }
    }
</style>
