<template>
    <editor-layout>
        <template #header>
            <editor-header
                :id="id"
                :title="`${mode} ${type}`"
                :item="item"
                :type="type"
                :mode="mode"
            />
        </template>
        <template #content>
            <form
                class="editor-form"
                @submit.prevent="openSaveModal"
            >
                <slot name="contentFormFields" />
            </form>
        </template>
        <template #modals>
            <editor-modals
                :type="type"
                :mode="mode"
                @checkRouteForValidate="$emit('checkRouteForValidate')"
                @reset="$emit('reset')"
                @editPost="$emit('editPost')"
                @createRevision="$emit('createRevision')"
            />
        </template>
    </editor-layout>
</template>

<script>
import EditorLayout from '@/layouts/EditorLayout';
// import EditorButtons from './components/EditorButtons';
import EditorModals from './components/EditorModals';
import EditorHeader from './components/EditorHeader';

export default {
    name: 'Editor',
    components: {
        EditorLayout,
        EditorModals,
        // EditorButtons,
        EditorHeader
    },
    props: {
        mode: String,
        type: String,
        item: Object,
        id: Number
    },
    created() {
        this.$bus.$off('editPost')
        this.$bus.$off('createPost')
        this.$bus.$off('resetPost')
        this.$bus.$off('createRevision')
        this.setEvents()
    },
    deactivated() {
        this.$bus.$emit('closeAllModals')
    },
    methods: {
        back() {
            this.$router.back()
        },
        openResetModal() {
            this.$bus.$emit('openModal', 'reset-modal')
        },
        openSaveModal() {
            this.$bus.$emit('openModal', 'save-modal')
        },
        setEvents() {
            this.$bus.$on('createRevision', () => {
                this.$bus.$emit('openModal', 'revision-modal')
            })
            this.$bus.$on('editPost', () => {
                this.$bus.$emit('openModal', 'edit-modal')
            })
            this.$bus.$on('createPost', () => {
                this.$bus.$emit('openModal', 'save-modal')
            })
            this.$bus.$on('resetPost', () => {
                this.$bus.$emit('openModal', 'reset-modal')
            })
        }
    }
}
</script>

<style scoped>

</style>
