<template>
    <div class="header_tabs">
        <router-link
            class="header_tabs-item"
            :class="$route.name === getPreviewLink && 'header_tabs-item--active'"
            :to="{
                name: getPreviewLink
            }"
        >
            Preview
        </router-link>
        <router-link
            class="header_tabs-item"
            :class="$route.name === `edit-post-${type}-history` && 'header_tabs-item--active'"
            :to="{
                name: `edit-post-${type}-history`
            }"
        >
            History
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        type: String
    },
    computed: {
        getPreviewLink() {
            if (this.type === 'landing' || this.type === 'blog' || this.type === 'essay') {
                return `edit-post-${this.type}-preview`
            }
            return `edit-post-${this.type}`
        }
    }
}
</script>

<style lang="scss" scoped>
    .header{
        &_tabs{
            position: relative;
            display: flex;
            margin: 10px 0;
            font-size: 18px;
            margin-bottom: 30px;
            &:after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                height: 3px;
                background: #D3D9E2;
            }
            &-item{
                padding: 15px 0;
                min-width: 150px;
                text-align: center;
                position: relative;
                transition: .3s all;
                color: #000;
                &--active{
                    font-weight: bold;
                    &:after{
                        content: '';
                        z-index: 2;
                        position: absolute;
                        height: 3px;
                        bottom: -2px;
                        left: 0;
                        right: 0;
                        background: $sidebar-orange;
                    }
                }
            }
        }
    }
</style>
