<template>
    <div class="editor-modals">
        <template v-if="mode === 'edit' || mode === 'revision' || mode === 'preview'">
            <confirm-modal
                :title="`Create revision ${type}?`"
                :content="'Are you sure?'"
                :name="'revision-modal'"
                @onConfirmClick="createRevision"
            />
            <confirm-modal
                :title="`Edit ${type}?`"
                :content="'Are you sure?'"
                :name="'edit-modal'"
                @onConfirmClick="editPost"
            />
            <confirm-modal
                :title="`Reset ${type}?`"
                :content="'Are you sure?'"
                :name="'reset-modal'"
                @onConfirmClick="reset"
            />
            <confirm-modal
                :title="`Save ${type}?`"
                :content="'Are you sure?'"
                :name="'save-modal'"
                @onConfirmClick="checkRouteForValidate"
            />
        </template>
        <template v-else-if="mode === 'create'">
            <confirm-modal
                :title="`Reset ${type}?`"
                :content="'Are you sure?'"
                :name="'reset-modal'"
                @onConfirmClick="reset"
            />
            <confirm-modal
                :title="`Save ${type}?`"
                :content="'Are you sure?'"
                :name="'save-modal'"
                @onConfirmClick="checkRouteForValidate"
            />
        </template>
    </div>
</template>

<script>
import modalsMixins from '@/mixins/modals/modalsMixins';

export default {
    name: 'EditorModals',
    mixins: [
        modalsMixins
    ],
    props: {
        type: String,
        mode: String
    },
    methods: {
        createRevision() {
            this.$emit('createRevision')
        },
        checkRouteForValidate() {
            this.$emit('checkRouteForValidate')
        },
        reset() {
            this.$emit('reset')
        },
        editPost() {
            this.$emit('editPost')
        }
    }
}
</script>

<style scoped>

</style>
