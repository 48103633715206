import { render, staticRenderFns } from "./EditorHeader.vue?vue&type=template&id=5cedb843&scoped=true&"
import script from "./EditorHeader.vue?vue&type=script&lang=js&"
export * from "./EditorHeader.vue?vue&type=script&lang=js&"
import style0 from "./EditorHeader.vue?vue&type=style&index=0&id=5cedb843&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cedb843",
  null
  
)

export default component.exports