<template>
    <div class="header_task">
        <div
            v-if="showFinalize"
            class="finalize_taks"
        >
            <TooltipComponent :text="'Finalize task'">
                <p
                    class="finalize_taks_button bubble bubble--action"
                    @click="openModal('finalize-modal')"
                >
                    <CheckAll />
                </p>
                <finalize-modal
                    :title="`Final task`"
                    :content="'Are you sure'"
                    :name="'finalize-modal'"
                    :prepend_data="item.post_links"
                    :is-valid="isValid"
                    :content-type="contentType"
                    @setFinalize="setFinalize"
                />
            </TooltipComponent>
        </div>
        <div
            v-if="showComplete"
            class="complete_taks"
        >
            <TooltipComponent :text="'Complete task'">
                <p
                    class="complete_taks_button bubble bubble--action"
                    @click="openModal('complete-modal')"
                >
                    <CheckOutline />
                </p>
                <confirm-modal
                    :title="`Complete task`"
                    :content="'Do you want complete task?'"
                    name="complete-modal"
                    @onConfirmClick="setCompleteTask()"
                />
            </TooltipComponent>
        </div>
        <div class="comment_task">
            <p
                v-if="item.comments"
                @click="openCommentsPopup"
            >
                <span
                    class="bubble"
                    :class="item.comments.length === 0 && 'bubble--empty'"
                >
                    {{ item.comments.length }}
                </span>
                <span>
                    Comments
                </span>
            </p>
            <TheCommentsTask
                v-if="showPopup"
                :id="item.id"
                v-click-outside="hidePopup"
                :comments="item.comments"
                @hideComments="hidePopup"
            />
        </div>
    </div>
</template>

<script>
import TooltipComponent from '@/components/TooltipComponent';
import TheCommentsTask from '@/components/comments/TheCommentsTask'
import FinalizeModal from '@/components/modals/FinalizeModal'

import CheckAll from 'mdi-vue/CheckAll'
import CheckOutline from 'mdi-vue/CheckOutline'

import {
    ACTION_FINALIZE_TASK,
    ACTION_COMPLETE_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

import modalsMixins from '@/mixins/modals/modalsMixins'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'EditorHeader',
    components: {
        TheCommentsTask,
        FinalizeModal,
        CheckAll,
        CheckOutline,
        TooltipComponent
    },
    mixins: [
        modalsMixins
    ],
    props: {
        item: Object
    },
    data() {
        return {
            showPopup: false,
            isValid: true
        }
    },
    computed: {
        getUser() {
            return this.$store.getters['auth/getterUser']
        },
        getUserIsAdmin() {
            return this.$store.getters['auth/getterUserIsAdmin']
        },
        showFinalize() {
            // return this.item.status <= 1 && this.item.assignee_id === this.getUser.id
            return true
        },
        showComplete() {
            // return (this.item.status === 2 && this.item.created_by === this.getUser.id) || this.getUserIsAdmin
            return true
        }
    },
    methods: {
        openCommentsPopup() {
            this.showPopup = true
        },
        hidePopup() {
            this.showPopup = false
        },
        ...mapTasksActions([
            ACTION_FINALIZE_TASK,
            ACTION_COMPLETE_TASK
        ]),
        async setFinalize(data) {
            try {
                await this.ACTION_FINALIZE_TASK({
                    id: this.item.id,
                    post_type: this.item.post_type,
                    post_links: data.post_links
                })
                this.$bus.$emit('showSnackBar', 'Task was update', 'success')
                data.callback()
                this.$router.push({ name: 'posts-list-tasks' })
            } catch (error) {
                this.isValid = false
                console.log(error.errors);
            }
        },
        async setCompleteTask() {
            try {
                await this.ACTION_COMPLETE_TASK({
                    tasks: [this.item.id]
                })
                this.$bus.$emit('showSnackBar', 'Task was update', 'success')
                this.$router.push({ name: 'posts-list-tasks' })
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .header_task{
        display:flex;
    }
    .finalize_taks{
        &_button{
            margin-right: 20px;
        }
    }
    .complete_taks{
        &_button{
            margin-right: 20px;
        }
    }
    .comment_task{
        & > p {
            display: flex;
            align-items: center;
             span {
                &:last-child{
                    margin-left: 10px;
                    font-weight: bold;
                }
             }
        }
    }
    .bubble{
        cursor: pointer;
        width: 60px;
        height: 60px;
        color: $orange;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $round-count;
        border-radius: 50%;
        &--empty{
            background: #e0e0e0;
            color: #000;
        }
        &--action{
            background: #1976D2;
            color: #fff;
        }
    }
</style>
