<template>
    <div
        v-click-outside="clickOutside"
        class="form-select"
    >
        <fieldset :class="openListItem ? 'form-select__fieldset active' : 'form-select__fieldset'">
            <legend
                class="form-select__legend"
            >
                Post Link
            </legend>
            <input
                v-model="setTextByValue"
                class="form-select__input"
                type="text"
                autocomplete="off"
                name="post_link"
                placeholder="Search by post slug"
                @focus="openListItem = true"
            >
            <ul
                v-if="openListItem"
                ref="scroll_list"
                class="form-select__list"
            >
                <template v-if="post_type">
                    <li
                        v-for="(item, index) in filteredList"
                        :key="index"
                        class="form-select__list-item"
                        @click="selectItem(item)"
                    >
                        <span class="form-select__list-item-title">
                            «{{ item.post_meta_title }}»
                        </span>
                        <span class="form-select__list-item-slug">
                            /{{ item.post_slug }}
                        </span>
                        <span class="form-select__list-item-date">
                            {{ item.created_at | moment_from }} ago {{ item.id }},
                            {{ item.published === 1 ? 'published' : 'unpublished' }}
                        </span>
                    </li>
                </template>
                <template v-else>
                    <li class="form-select__list-item">
                        <span class="form-select__list-item-title">
                            Please, check post type
                        </span>
                    </li>
                </template>
                <template v-if="filteredList.length === 0 && post_type">
                    <li class="form-select__list-item">
                        <span class="form-select__list-item-title">
                            Not Found
                        </span>
                    </li>
                </template>
            </ul>
            <div
                class="select-arrow"
            >
                <arrow />
            </div>
        </fieldset>
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js'
import ClickOutside from 'vue-click-outside'
import { mapTaskFields } from '@/store/modules/content/content-edit/tasks/index.js'
import filtersMixin from '@/mixins/filtersMixin'
import Arrow from '@/icons/Arrow';

export default {
    name: 'FormSelectLink',
    directives: {
        ClickOutside
    },
    components: {
        Arrow
    },
    mixins: [
        filtersMixin
    ],
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'naming', 'prepend_type'
    ],
    data() {
        return {
            openListItem: false,
            search: '',
            filteredList: [],
            page: 1,
            isLoading: false,
            post_type: null,
            timeout: null
        };
    },
    computed: {
        ...mapTaskFields({
            post_type_store: 'task.form_data.post_type',
            task_id: 'task.form_data.id'
        }),
        getFilter() {
            return {
                status: 'all',
                search_by: 'post_slug',
                search_for: this.search,
                page: this.page,
                per_page: 10,
                sort_by: 'updated_at',
                sort_order: 'DESC'
            }
        },
        setTextByValue: {
            get() {
                if (this.value?.includes(process.env.VUE_APP_API_URL)) {
                    return this.value
                }
                return this.search
            },
            set(val) {
                if (this.timeout) clearTimeout(this.timeout)
                this.timeout = setTimeout(async () => {
                    this.search = val
                    this.$emit('input', '');
                    await this.getListByType()
                }, 300)
            }
        }
    },
    watch: {
        async post_type_store() {
            this.post_type = this.post_type_store
            this.search = ''
            this.$emit('input', this.value);
            await this.getListByType()
        },
        task_id() {
            this.search = ''
            this.$emit('input', this.value);
        },
        openListItem(val) {
            if (val) {
                this.$nextTick(async () => {
                    await this.getListByType()
                    const list = this.$refs.scroll_list
                    list.addEventListener('scroll', this.scrollListner)
                });
            } else {
                const list = this.$refs.scroll_list
                list.removeEventListener('scroll', this.scrollListner)
                this.page = 1
                this.search = ''
            }
        }
    },
    async created() {
        if (this.prepend_type) {
            this.post_type = this.prepend_type
        } else if (this.post_type_store) {
            this.post_type = this.post_type_store
        }
    },
    async activated() {
        if (this.post_type) {
            this.setInitValue()
            await this.getListByType()
        }
    },
    methods: {
        async scrollListner(e) {
            const element = e.currentTarget
            if (element.scrollHeight - 50 < element.scrollTop + element.clientHeight) {
                if (this.isLoading === false) {
                    const lastTop = element.scrollTop;
                    this.page += 1
                    this.isLoading = true
                    await this.loadmore()
                    element.scrollTop = lastTop
                }
            }
        },
        async loadmore() {
            if (this.post_type) {
                const response = await Api.get(`/api/content/posts/${this.post_type}/list-posts`, this.getFilter)
                this.filteredList = [...this.filteredList, ...response.data.data]
                this.isLoading = false
            }
        },
        async getListByType() {
            if (this.post_type) {
                const response = await Api.get(`/api/content/posts/${this.post_type}/list-posts`, this.getFilter)
                this.filteredList = response.data.data
                this.isLoading = false
            }
        },
        async selectItem(value) {
            const previewLink = `${process.env.VUE_APP_API_URL}/content/post/${this.post_type}/${value.id}/preview`
            await this.$emit('input', previewLink);
            this.openListItem = false;
            this.search = '';
        },
        setInitValue() {
            this.search = this.value;
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss" scoped>
    .form-select {
        outline: none;
        border: 1px solid #27d91f ;
        max-height: 52px!important;
        width: 100%;

        &__fieldset {
            position: relative;
            border: none ;
            outline: none;

        }

        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -12px;
            background-color: white;
            border: none;
        }

        &__input {
            box-sizing: border-box;
            display: inline-block;
            padding-left: 10px;
            width: 100%;
            cursor: pointer;
            line-height: 50px;
            border: none ;
            outline: none;
            font-family: 'Mark Pro', sans-serif;
            max-height: 46px;
            text-transform: lowercase;
        }

        &__list {
            overflow-y: scroll;
            max-height: 270px;
            background-color: white;
            position: absolute;
            z-index: 10;
            top: 55px;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            border-radius: 4px;
        }

        &__list-item {
            padding: 15px;
            background-color: #fff;
            margin-bottom: 1px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            &:hover {
                background-color: lightgrey;
            }
            &-title{
                width: 100%;
                margin-bottom: 10px;
            }
            &-date{
                color: rgba(0,0,0,.6);
            }
            &-slug{
                color: rgba(0,0,0,.6);
                margin-bottom: 10px;
                text-transform: lowercase;
            }
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            // color: $error-color;
            // caret-color: $error-color;
            font-size: 12px;
            min-height: 14px;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: white;
            padding: 0 3px;
        }
    }
</style>
